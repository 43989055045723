<template>
  <div class="root">
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed w-screen h-screen inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="bg-black bg-opacity-90 fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="
                  inline-block
                  w-full
                  max-w-4xl
                  py-8
                  px-12
                  my-4
                  overflow-hidden
                  text-left
                  align-middle
                  transition-all
                  transform
                  bg-white
                  shadow-xl
                  rounded-lg
                "
              >
                <button
                  class="text-red text-3xl float-right"
                  @click="setIsOpen(false)"
                >
                  ✕
                </button>
                <DialogTitle
                  as="h1"
                  class="text-4xl my-4 font-bold leading-6 text-gray-900"
                >
                  {{ historia.title }}
                  <hr />
                </DialogTitle>
                <div>
                  <p
                    class="
                      text-base text-gray-800
                      leading-7
                      whitespace-pre-line
                    "
                  >
                    {{ historia.full }}
                  </p>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Banner
      title="Grupo LON"
      image="img/headers/grupo.jpg"
      :icon="grupo.icon"
    />
    <!-- <CardSelector :items="[]" /> -->
    <div class="component3 overflow-hidden my-16">
      <div class="mx-auto min-h-screen md:flex relative">
        <img
          class="object-cover w-full lg:ml-48"
          :src="historia.img"
          alt=""
        />
        <div class="lg:bg-white floating-text z-10 block w-full">
          <div class="p-8 lg:p-16 h-full w-full relative">
            <h1 class="text-center lg:text-left mb-4">
              {{ historia.title }}
            </h1>
            <hr class="mx-auto lg:ml-0"/>
            <p class="pb-4 overflow-ellipsis h-2/3" v-html="historia.text">
            </p>
            <a
              @click="setIsOpen(true)"
              class="text-blue-400 cursor-pointer font-serif text-gray-600"
              to="#"
            >
              Seguir leyendo...
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="component bg-gray-100 py-8">
      <h1 class="mx-auto pt-8 text-center">Delegaciones</h1>
      <hr class="mx-auto mb-0" />
      <div class="w-full max-w-7xl px-8 py-8 sm:px-0 mx-auto">
        <TabGroup :defaultIndex="0">
          <TabList
            class="
              flex flex-wrap
              lg:flex-nowrap
              px-8
              justify-center
              lg:px-4
              lg:space-x-4
              rounded-xl
            "
          >
            <Tab
              v-for="(item, index) in grupo.delegaciones"
              as="template"
              :key="index"
              v-slot="{ selected }"
            >
              <button
                :class="[
                  'w-full m-2 max-w-xs py-2.5 text-base font-medium text-white rounded-full',
                  'focus:outline-none',
                  'font-sans',
                  selected ? 'bg-blue-400' : 'bg-gray-400',
                ]"
              >
                {{ item.title }}
              </button>
            </Tab>
          </TabList>

          <TabPanels class="mt-8">
            <TabPanel
              v-for="(item, index) in grupo.delegaciones"
              :key="index"
              :class="['rounded-xl p-3', 'focus:outline-none']"
            >
              <div class="mx-auto">
                <div class="bg-gray-100">
                  <div class="content lg:flex">
                    <img
                      class="
                        image-hostelero
                        object-cover
                        overflow-hidden
                        w-full
                        lg:max-w-2xl
                      "
                      :src="item.img"
                      alt=""
                    />
                    <div class="content-text lg:p-12 text-center lg:text-left">
                      <h2 class="my-12">{{ item.title }}</h2>
                      <div class="max-w-2xl leading-loose lg:text-justify">
                        <p class="mb-8">{{ item.descr }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
    <div class="component p-8">
      <div class="container mx-auto text-center">
        <h1 class="text-4xl mx-auto">Logros de la Empresa</h1>
        <hr class="mx-auto" />
        <p class="max-w-4xl">
          Grupo LON ha puesto todo su esfuerzo y empeño en los últimos años para
          conseguir las certificaciones ISO más relevantes en el sector de
          lavandería industrial. El objetivo es seguir avanzando hacia la
          excelencia y cumplir con nuestros compromisos, valores y misión
          corporativa.
        </p>
        <div class="isos mx-auto mt-16 lg:px-32">
          <div
            v-for="(iso, index) in grupo.isos"
            :key="index"
            class="lg:flex items-center my-12 lg:my-0"
          >
            <img :src="iso.image" :alt="`iso-${index}`" class="h-36 w-36 p-4 mx-auto" />
            <div class="text-container lg:pl-16 text-center lg:text-left">
              <h3 class="font-bold mb-4">{{ iso.title }}</h3>
              <p>{{ iso.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="component px-8 lg:px-24 pt-16 mb-8">
      <div class="container mx-auto text-center">
        <h1 class="mx-auto">Responsabilidad Social Corporativa</h1>
        <hr class="mx-auto" />
        <p class="max-w-4xl">
          Desde nuestra constitución, hemos puesto el foco en las personas. Por
          eso, nuestro modelo de liderazgo se basa en la gestión de la
          diversidad, la igualdad de oportunidades y la inclusión social.<br /><br />Nos
          enorgullecemos de la transparencia de Grupo LON en la relación con
          todos sus grupos de interés respecto a los temas económicos, sociales
          y medioambientales. También de impulsar cada día la mejora de la
          satisfacción de los empleados y de la imagen frente a los clientes y
          resto de agentes externos.
        </p>
      </div>
    </div>
      <div class="my-24">
        <Expandables :items="grupo.expandables" />
      </div>
    <div class="component4 bg-blue-500">
      <div class="container mx-auto">
        <div class="lg:flex py-16 px-8 justify-around center-items">
          <div class="text-white lg:mr-48">
            <h1 class="text-4xl mb-4">Acepta el reto</h1>
            <p class="mb-8 lg:mb-0">
              No dejes pasar esta oportunidad, ponte en contacto con nosotros.
            </p>
          </div>
          <router-link
            :to="{ path: '/contacto', hash: '#formulario' }"
            class="
              block
              bg-white
              text-blue-400
              rounded-md
              px-12
              py-3
              w-auto
              self-center
              hover:text-blue-900
            "
            >Contactar</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Banner from "@/components/Banner.vue";
import Expandables from "@/components/Expandables.vue";
import { grupo } from "@/vars";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionRoot,
  TransitionChild,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@headlessui/vue";

export default {
  components: {
    Banner,
    Expandables,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  setup() {
    let isOpen = ref(false);
    return {
      isOpen,
      setIsOpen(value) {
        isOpen.value = value;
      },
      grupo,
      historia: grupo.historia,
    };
  },
};
</script>
<style>
@screen lg {
  .floating-text {
    position: absolute;
    left: 0;
    top: 8rem;
    max-width: 50%;
    max-height: 75%;
  }
}
.image-1 {
  height: 32rem;
}
</style>
